(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/limited-time-popover/assets/javascripts/limited-time-popover.js') >= 0) return;  svs.modules.push('/components/lb-ui/limited-time-popover/assets/javascripts/limited-time-popover.js');
"use strict";

const DEVICE_SESSION_DOMAIN = 'limitedTimePopover';
const POPOVER_ZINDEX = 1998; 
const POPOVER_TRANSITION_TIME = 200; 

const showPopover = (text, theme, placement, offset, target, hideTime) => {
  const targetEl = document.querySelector(target);
  if (!targetEl) {
    return;
  }
  let timeout;
  const tippyInstance = tippy(targetEl, {
    content: text,
    duration: POPOVER_TRANSITION_TIME,
    theme,
    trigger: 'manual',
    hideOnClick: true,
    placement,
    offset,
    popperOptions: {
      modifiers: [{
        name: 'flip',
        enabled: false
      }]
    },
    onHide() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    },
    zIndex: POPOVER_ZINDEX
  });
  tippyInstance.show();
  timeout = setTimeout(() => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    tippyInstance.hide();
  }, hideTime);
};
const showLimitedTimePopover = _ref => {
  let {
    text,
    target,
    key = null,
    theme,
    placement,
    offset,
    waitTime = 2000,
    hideTime = 8000,
    maxShowCount = 1
  } = _ref;
  if (!text || text.length < 1) {
    throw new Error('text is required');
  }
  if (hideTime < 1) {
    throw new Error('hideTime has to be larger than 0');
  }
  const targetEl = document.querySelector(target);
  if (!targetEl) {
    return;
  }
  if (!key || key.length < 1) {
    key = target + text;
  }
  const lowerKey = key.replace(/\W/g, '').toLowerCase();
  const timeout = setTimeout(() => {
    clearTimeout(timeout);
    svs.core.deviceSession.get(lowerKey, DEVICE_SESSION_DOMAIN, res => {
      if (!res || res < Math.max(maxShowCount, 1)) {
        var _svs$components$dialo, _svs$components;
        const triggerShowPopover = () => {
          svs.core.deviceSession.set(lowerKey, DEVICE_SESSION_DOMAIN, res + 1);
          if (!theme) {
            theme = 'default';
          }
          showPopover(text, theme, placement, offset, target, hideTime);
        };
        if ((_svs$components$dialo = (_svs$components = svs.components) === null || _svs$components === void 0 || (_svs$components = _svs$components.dialog) === null || _svs$components === void 0 || (_svs$components = _svs$components.api) === null || _svs$components === void 0 ? void 0 : _svs$components.isBlocking) !== null && _svs$components$dialo !== void 0 ? _svs$components$dialo : false) {
          svs.components.dialog.api.on('blocking', isBlocking => {
            if (!isBlocking) {
              svs.components.dialog.api.off('blocking');
              triggerShowPopover();
            }
          });
        } else {
          triggerShowPopover();
        }
      }
    });
  }, Math.max(waitTime, 0));
};
setGlobal('svs.lb_ui.limitedTimePopover.showLimitedTimePopover', showLimitedTimePopover);

 })(window);